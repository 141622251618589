<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{ item.id ? 'Edit' : 'New' }} supplement<br>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to areas & groups list"
              @click="$router.push({name: 'researcher.administration.supplements.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              v-if="loggedUser.roles.includes('super-admin')"
              class="btn btn-icon btn-flat-secondary"
              @click="$store.dispatch('modals/toggleRequestModal', 'Supplement')"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div
          v-if="errors != ''"
          class="alert alert-danger mt-1 p-1"
        >
          {{ errors }}
        </div>
        <div class="row">
          <div class="col-12">
            <SearchResearcher :item="item" />

            <div class="card">
              <div
                v-b-toggle="`collapseContent`"
                class="card-header"
              >
                <h3 class="cart-title">
                  <a data-action="collapse">Content</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <b-collapse
                  :id="`collapseContent`"
                  visible
                >
                  <!-- sólo se muestra "show" si hay texto en el textarea -->
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.type'] }}</label>
                          <v-select
                            v-model="item.type"
                            label="name"
                            :options="types"
                            :get-option-key="option => option.id"
                          />
                        </div>
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.comments'] }}</label>
                          <quill-editor v-model="item.comments" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.begin_date'] }}</label>
                          <date-picker
                            v-model="item.begin_date"
                            format="D MMM Y"
                            value-type="format"
                            class="w-100"
                          />
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.end_date'] }}</label>
                          <date-picker
                            v-model="item.end_date"
                            format="D MMM Y"
                            value-type="format"
                            class="w-100"
                          />
                        </div>
                      </div>
                    </div>
                    <hr>
                    <div class="row">
                      <div class="col-sm-3">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.amount'] }}</label>
                          <div class="input-group">
                            <input
                              v-model="item.amount"
                              type="number"
                              class="form-control"
                              placeholder="0,00"
                              aria-label="0,00"
                              aria-describedby="basic-addon2"
                              step="0.01"
                              @change="decimalFormat($event.target)"
                            > <!-- poner máscara numérica con dos decimales -->
                            <span
                              id="basic-addon2"
                              class="input-group-text"
                            >€</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>

            <IcreaRemarks
              :item="item"
            />

            <AddFile
              :id="6"
              :title="'Support documentation'"
              :type="2"
              :files="item.files_"
            />

            <InternalNotes
              :notes="item.notes"
              @saveNewNote="saveNewNote"
            />

            <AttachedForm
              :type="'Supplement'"
              :model-id="supplementId"
            />
          </div>
        </div>

        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" />
            <span class="ms-25 align-middle">Save and Exit</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import InternalNotes from '@/views/back/partials/components/InternalNotes.vue'
import DatePicker from 'vue2-datepicker'
import vSelect from 'vue-select'
import IcreaRemarks from '@/views/back/partials/components/IcreaRemarks.vue'
import AddFile from '../../../admin/senior-call/components/AddFile.vue'
import SearchResearcher from '../../../partials/components/SearchResearcher.vue'
import AttachedForm from '../../../forms/partials/AttachedForm.vue'
import 'vue2-datepicker/index.css'

export default {
  components: {
    AddFile,
    SearchResearcher,
    InternalNotes,
    IcreaRemarks,
    DatePicker,
    BCollapse,
    vSelect,
    AttachedForm,
  },
  data() {
    return {
      supplementId: this.$route.params.id,
      sending: false,
      errors: '',
    }
  },
  computed: {
    ...mapGetters({
      item: 'supplements/item',
      loggedUser: 'auth/admin',
      types: 'supplementTypes/items',
      labels: 'sectionLabels/itemLabels',
      user: 'users/user',
      attached: 'requestForms/attached',
    }),
  },
  async mounted() {
    this.$store.dispatch('sectionLabels/fetchLabels', 'administration.supplements')
    if (this.supplementId) {
      await this.$store.dispatch('supplements/fetchId', this.supplementId)
      this.decimalFormat(this.item.amount)
    } else {
      await this.$store.dispatch('supplements/cleanType')
      Vue.set(this.item, 'user', this.user)
    }

    await this.$store.dispatch('supplementTypes/fetchActives')
  },
  methods: {
    saveFiles(files) {
      this.item.files_ = files.data
    },
    async save() {
      this.sending = true
      this.item.attached_form = this.attached
      try {
        if (this.supplementId) {
          await this.$store.dispatch('supplements/update', { id: this.supplementId, data: this.item })
        } else {
          await this.$store.dispatch('supplements/create', this.item)
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.item.id) {
        Vue.swal('The supplement history has been saved correctly!', '', 'success').then(() => {
          this.$router.push({ name: 'researcher.administration.supplements.index' })
        })
      } else {
        // Vue.swal
      }

      this.sending = false
    },
    saveNewNote(note) {
      this.item.notes.push(note)
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    decimalFormat(amount) {
      // Obtén el valor ingresado en el input
      let aux = 0
      if (amount.value) {
        aux = parseFloat(amount.value)
      } else {
        aux = parseFloat(amount)
      }
      const valor = aux

      // Si el valor es un número válido
      if (!Number.isNaN(valor)) {
        // Formatea el valor con 2 decimales
        const valorFormateado = valor.toFixed(2)
        // Actualiza el valor en el input
        // eslint-disable-next-line no-param-reassign
        // amount.value = valorFormateado
        this.item.amount = valorFormateado
      }
    },
  },
}
</script>
